import axios from "axios";
import EndPoints from "./endpoints";

class Error {
  constructor(code, message, retry) {
    this.code = code;
    this.message = message;
    this.retry = retry;
  }

  toString() {
    return this.message + this.code;
  }
}
const httpMethods = [
  "options",
  "get",
  "head",
  "delete",
  "patch",
  "post",
  "put",
];
const ERROR_NOT_AUTHENTICATED = 401;
const ERROR_NOT_AUTHORIZED = 403;
const defaultOptions = {
  axios: {
    method: "get",
    responseType: "json",
  },
  errors: {
    "-400": new Error(-400, "error.missing_parameters", false),
    0: new Error(0, "error.no_response", true),
    400: new Error(400, "error.incorrect_request", false),
    404: new Error(404, "error.not_found", false),
    500: new Error(500, "error.server_error", false),
    [ERROR_NOT_AUTHENTICATED]: new Error(
      ERROR_NOT_AUTHENTICATED,
      "error.not_authenticated",
      false
    ),
    [ERROR_NOT_AUTHORIZED]: new Error(
      ERROR_NOT_AUTHORIZED,
      "error.no_permission",
      false
    ),
  },
  disableRetry: false,
  maxRetries: 5,
  minimumRetryTime: 1000,
};
const handleError = (error, options, axiosOptions, promise, attempts = 1) => {
  let errorCode = 0;
  let errorMessage = null;
  if (error && error.response) {
    errorCode = parseInt(error.response.status) || 500;
    const errorData = error.response.data;
    // Error message sent by the server has precedence over local error messages
    // If the server wants to send a custom error message, the html body response
    // expected is a json with this format: {"errorCode": "errorCode", "message":"errorMessage"}
    if (errorData && errorData.message) {
      errorMessage = new Error(errorCode, errorData.message, false);
    } else {
      errorMessage = options.errors[errorCode];
    }
  } else {
    // No response from server, set the default 'no response' error code
    errorMessage = options.errors[errorCode];
  }
  errorMessage = errorMessage || options.errors[500];
  if (errorMessage.retry && attempts <= options.maxRetries) {
    const cooldownTime = options.minimumRetryTime * Math.pow(2, attempts - 1);
    setTimeout(function () {
      axios
        .request(axiosOptions)
        .then((res) => promise.resolve(res))
        .catch((error) =>
          handleError(error, options, axiosOptions, promise, attempts + 1)
        );
    }, cooldownTime);
  } else {
    promise.reject(errorMessage);
  }
};
const request = (methodOptions, url, template, apiOptions) => {
  template = Object.assign({}, template);
  return (parameters, invokerOptions) => {
    if (!(parameters instanceof FormData))
      parameters = Object.assign({}, parameters);

    const options = Object.assign(
      {},
      defaultOptions,
      methodOptions,
      apiOptions,
      invokerOptions
    );
    for (const parameter in template) {
      if (typeof template[parameter] === "string") {
        if (parameters instanceof FormData) {
          parameters.append(parameter, template[parameter]);
        } else {
          parameters[parameter] = template[parameter];
        }
        continue;
      }
      if (!template[parameter]) continue;
      if (parameters instanceof FormData) {
        if (!parameters.has(parameter))
          return Promise.reject(options.errors[-400]);
      } else {
        if (
          parameters[parameter] !== 0 &&
          (parameters[parameter] === undefined ||
            parameters[parameter] === null)
        )
          return Promise.reject(options.errors[-400]);
      }
    }
    const axiosOptions = Object.assign({}, options.axios);
    axiosOptions.url = typeof url === "function" ? url(parameters) : url;

    if (httpMethods.indexOf(options.axios.method) > 3) {
      axiosOptions.data = parameters;
    } else {
      axiosOptions.params = parameters;
    }

    if (!axiosOptions.headers) axiosOptions.headers = {};

    if ("headers" in options)
      Object.keys(options.headers).forEach((key) => {
        axiosOptions.headers[key] = options.headers[key];
      });

    return new Promise((resolve, reject) => {
      axios
        .request(axiosOptions)
        .then((res) => resolve(res))
        .catch((error) =>
          handleError(error, options, axiosOptions, { resolve, reject })
        );
    });
  };
};
const httpGetMethod = request.bind(null, { axios: { method: "get" } });
const getBinary = request.bind(null, {
  axios: { method: "get", responseType: "arraybuffer" },
});

const dynamicUrl = (strings, ...keys) => {
  return (parameters) => {
    if (!parameters) parameters = {};
    let result = strings[0];
    for (let i = 1; i < strings.length; i++) {
      const key = keys[i - 1] || "";
      // Decide if it's either a static string, or parametrized
      // It's looking for the following format: ${parameter_name}
      const matches = /^\${(.+)}$/.exec(key);
      if (matches && matches[1]) {
        const parameter = matches[1].trim();
        if (parameters instanceof FormData) {
          if (parameters.has(parameter)) {
            result += parameters.get(parameter);
            parameters.delete(parameter);
          }
        } else {
          result += parameters[parameter] || "";
          if (parameters[parameter]) delete parameters[parameter];
        }
      } else {
        result += key;
      }
      result += strings[i];
    }
    return result;
  };
};

const PARAM_ID = "${_id}";
const required = true;

export default {
  files: {
    getDocumentsByUnit: httpGetMethod(
      dynamicUrl`${EndPoints.FotaApp}/units/${PARAM_ID}/documents`,
      {
        _id: required,
      }
    ),
    getPdfFile: getBinary(
      dynamicUrl`${
        EndPoints.FotaApp
      }/units/${PARAM_ID}/documents/${"${documentUuid}"}`,
      {
        _id: required,
        documentUuid: required,
      },
      { headers: { Accept: "application/pdf" } }
    ),
  },
  units: {
    getById: httpGetMethod(
      dynamicUrl`${EndPoints.Management}/units/${PARAM_ID}/public-details`,
      {
        _id: required,
      }
    ),
  },
  Error,
};
