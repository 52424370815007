import { createRouter, createWebHistory } from "vue-router"

export const routes = [
  {
    path: "/",
    name: "home",
    components: {},
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

export default router
