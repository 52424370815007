<template>
  <v-container>
    <div v-if="fetching">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-if="!fetching">
      <img alt="Vue logo" height="30" width="30" src="../assets/ce_logo.png" />
      <img
        alt="Vue logo"
        height="30"
        width="60"
        src="../assets/euro_logo.png"
      />
      <hr />
      <h1>{{ identifier }}</h1>
      <h5>{{ unitType }}</h5>
      <hr />
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-sheet class="ma-2 pa-2"> <b>Application version:</b> </v-sheet>
          <v-sheet class="ma-2 pa-2"> {{ application }} </v-sheet>
        </v-col>
        <v-col cols="12" sm="4">
          <v-sheet class="ma-2 pa-2"> <b>Bootloader version:</b> </v-sheet>
          <v-sheet class="ma-2 pa-2"> {{ bootloader }} </v-sheet>
        </v-col>
        <v-col cols="12" sm="4">
          <v-sheet class="ma-2 pa-2"> <b>Hardware version:</b> </v-sheet>
          <v-sheet class="ma-2 pa-2"> {{ hardwareVersion }} </v-sheet>
        </v-col>
        <v-col cols="12" sm="4">
          <v-sheet class="ma-2 pa-2"> <b>Activation date:</b> </v-sheet>
          <v-sheet class="ma-2 pa-2"> {{ activationDate }} </v-sheet>
        </v-col>
        <v-col cols="12" sm="4">
          <v-sheet class="ma-2 pa-2"> <b>Uuid:</b> </v-sheet>
          <v-sheet class="ma-2 pa-2">
            {{ uuid }}
          </v-sheet>
        </v-col>
      </v-row>
      <hr />
      <h3>Manuals</h3>
      <p>For a guide on how to configure / install this unit</p>
      <v-list lines="one">
        <v-list-item v-for="(document, index) in documents" v-bind:key="index">
          <v-btn
            class="cq-button cq-button-default btn"
            @click="(e) => download(document.uuid)"
          >
            {{ document.name }}
          </v-btn>
        </v-list-item>
      </v-list>

      <hr />
      <h3>Contact</h3>

      <p>
        Science Park<br />

        Gjuterigatan 9<br />

        553 18 Jönköping<br />

        +46 (0)36-33 20 820<br />

        contact@combiq.com
      </p>
      <ul>
        <li>
          <a href="https://www.combiq.com" target="_blank" rel="noopener"
            >https://www.combiq.com</a
          >
        </li>
        <li>
          <a href="https://app.combiq.com" target="_blank" rel="noopener"
            >https://app.combiq.com</a
          >
        </li>
      </ul>
    </div>
    <v-dialog v-model="dialog" width="auto">
      <v-card
        max-width="400"
        prepend-icon="mdi-update"
        text="Unit not found."
        title="Error"
      >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from "../api";

export default {
  name: "ShowUnit",
  data() {
    return {
      dialog: false,
      documents: [],
      fetching: true,
      uuid: "",
      identifier: "",
      activationDate: "",
      bootloader: "",
      hardwareVersion: "",
      application: "",
      unitType: "",
    };
  },
  created() {
    setTimeout(() => {
      if (this.$route.query.UUID) {
        this.getUnit();
      }
    }, 1000);
  },
  methods: {
    getUnit() {
      this.fetching = true;
      Api.units
        .getById({
          _id: this.$route.query.UUID,
        })
        .then((res) => {
          this.identifier = res.data.identifier;
          this.uuid = res.data.uuid;
          this.activationDate = res.data.activationDate;
          this.bootloader = res.data.bootloader;
          this.application = res.data.application;
          this.unitType = res.data.type;
          this.hardwareVersion = res.data.hardwareVersion;
        })
        .catch((error) => {
          this.dialog = true;
          console.log(error);
        })
        .finally(() => {
          this.fetching = false;
        });
      Api.files
        .getDocumentsByUnit({
          _id: this.$route.query.UUID,
        })
        .then((res) => {
          this.documents = res.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    download(uuid) {
      this.dataFetched = false;
      Api.files
        .getPdfFile({
          _id: this.$route.query.UUID,
          documentUuid: uuid,
        })
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.fetching = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
